import React from "react";
import { useTranslation } from "react-i18next";
import { handleSignup } from "./Navbar";

const Contact = () => {
  const { t, i18n } = useTranslation();

  // Determine if the current language is Japanese
  const isJapanese = i18n.language === "ja";

  // Set languages based on the detected language
  const languages = isJapanese
    ? ["jp", "lr3", "uk", "us", "au"]
    : ["jp", "es", "de", "ru", "mn", "it", "fr"];

  return (
    <div className="contact-page-wrapper">
      <h1 className="primary-heading">{t("contact.primary-heading")}</h1>
      <p className="primary-text text-center">{t("contact.description")}</p>

      <div className="flex flex-wrap justify-center md:space-x-4">
        {languages.map((lang) => (
          <img
            key={lang}
            src={`https://github.com/rcwachter/sudoto-public/blob/main/LangFlags/${lang}.png?raw=true`}
            alt={`${lang} flag`}
            className="w-24 h-24 m-2"
          />
        ))}
      </div>

      <div className="about-buttons-container">
        <button className="secondary-button" onClick={handleSignup}>
          {t("contact.cta")}
        </button>
      </div>
    </div>
  );
};

export default Contact;

/*
return (
    <div className="contact-page-wrapper">
      <h1 className="primary-heading">Have a question?</h1>
      <h1 className="primary-heading">Let us help you!</h1>
      <div className="contact-form-container">
        <input type="text" placeholder="support@sudoto.app" />
        <button className="secondary-button">Submit</button>
      </div>
    </div>
  );
  */
